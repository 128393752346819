export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'day',
    sortable: false,
    sortField: 'day',
    label: 'field.day',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'openTime',
    sortable: false,
    sortField: 'open_time',
    label: 'field.open_time',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'closeTime',
    sortable: false,
    sortField: 'close_time',
    label: 'field.close_time',
    stickyColumn: true,
    variant: 'light',
  },

  {
    key: 'actions',
    label: 'general.action',
  },
]
